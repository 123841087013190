// i18next-extract-mark-ns-start home

import React, { useContext } from 'react'
import Layout from 'src/components/layout'
import { Helmet } from 'react-helmet'
import { useTranslation, I18nextContext } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import { DynamicallyStyledPageTitle } from 'src/components/typography/PageTitle'
import appleStoreButton from 'src/images/SVG/log-app/download-apple-store.svg'
import googlePlayButton from 'src/images/SVG/log-app/download-google-play.svg'
import arrowSvg from 'src/images/SVG/log-app/arrow.svg'
import HighlightedSubtitle from 'src/components/typography/HighlightedSubtitle'
import { useSwipeable } from 'react-swipeable'
import styled from 'styled-components'
import tw from 'twin.macro'

const INDEX_SHIFT = 1

const StyledTitle = styled.div`
    & > * {
      ${tw`text-center lg:text-left`}
    }
  }
`

const IndexPage = ({ data }) => {
  const { t } = useTranslation('logapp')
  const { title, description } = data.logApp.seo
  const { language } = useContext(I18nextContext)

  const slides = [
    {
      index: 0,
      title: t('slide-title-1'),
      subtitle: t('slide-subtitle-1'),
    },
    {
      index: 1,
      title: t('slide-title-2'),
      subtitle: t('slide-subtitle-2'),
    },
    {
      index: 2,
      title: t('slide-title-3'),
      subtitle: t('slide-subtitle-3'),
    },
    {
      index: 3,
      title: t('slide-title-4'),
      subtitle: t('slide-subtitle-4'),
    },
    {
      index: 4,
      title: t('slide-title-5'),
      subtitle: t('slide-subtitle-5'),
    },
    {
      index: 5,
      title: t('slide-title-6'),
      subtitle: t('slide-subtitle-6'),
    },
  ]

  const [currentSlide, setCurrentSlide] = React.useState(slides[0])

  const setSlideByIndex = (index: number) => {
    setCurrentSlide(slides[index])
  }

  const goToNextSlide = () => {
    const nextSlideIndex = currentSlide.index + INDEX_SHIFT

    if (nextSlideIndex > slides.length - INDEX_SHIFT) {
      setSlideByIndex(0)
      return
    }
    setSlideByIndex(nextSlideIndex)
  }

  const goToPreviousSlide = () => {
    const previousSlideIndex = currentSlide.index - INDEX_SHIFT

    if (previousSlideIndex < 0) {
      setSlideByIndex(slides.length - INDEX_SHIFT)
      return
    }
    setSlideByIndex(previousSlideIndex)
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => goToNextSlide(),
    onSwipedRight: () => goToPreviousSlide(),
    delta: 10,
  })

  const getSlidesIndicators = () =>
    slides.map((slide, index) => (
      <button
        type="button"
        className={`${
          index === slides.length - INDEX_SHIFT ? '' : 'mr-2 lg:mr-4'
        }`}
        onClick={() => setSlideByIndex(index)}
      >
        <div
          className={`h-0.5 w-5 lg:w-10 ${
            slide.index === currentSlide.index
              ? 'bg-darkGray'
              : 'bg-veryLightGray'
          }`}
        />
      </button>
    ))

  const slideImageSrc = `/images/log-app/slides/${
    currentSlide.index + 1
  }/${language}-sm.png`

  const slideImageSrcRetina = `/images/log-app/slides/${
    currentSlide.index + 1
  }/${language}.png`

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Layout>
        <div className="relative bg-logAppBackgroundPlaceholder bg-log-app-mobile lg:bg-log-app bg-cover bg-center lg:min-h-[80vh] 2xl:flex 2xl:items-center min-h-0.87screen md:min-h-0.7screen flex items-center">
          <div className="px-4 lg:px-0 lg:max-w-[1920px] mx-auto">
            <div className="flex pb-4 pt-8 lg:py-24 lg:px-[250px] items-center">
              <div className="2xl:w-1/2">
                <DynamicallyStyledPageTitle isSticked={false} className="!mb-8">
                  <span className="hidden md:inline text-brightYellow 2xl:text-left whitespace-pre-wrap w-full">
                    {t('ALL THE LAWS OF \nFOOTBALL NOW\nAVAILABLE IN ONE APP')}
                  </span>
                  <span className="md:hidden text-brightYellow 2xl:text-left whitespace-pre-wrap w-full">
                    {t('mobile_title')}
                  </span>
                </DynamicallyStyledPageTitle>
                <h6 className="text-white uppercase 2xl:pr-48 font-light text-center 2xl:text-left">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t(
                        'With the official app of the ifab, you can always access the laws of the game wherever you are - '
                      ),
                    }}
                  />
                  <strong className="font-bold">
                    {t('online and offline!')}
                  </strong>
                </h6>
                <div className="flex w-full justify-center 2xl:justify-start mt-28 lg:mt-8">
                  <a href={t('apple-app-store-link')} target="_blank" rel="noreferrer">
                    <img src={appleStoreButton} alt="" className="h-[48px]" />
                  </a>
                  <a href={t('google-play-link')} target="_blank" rel="noreferrer">
                    <img
                      src={googlePlayButton}
                      alt=""
                      className="ml-6 h-[48px]"
                    />
                  </a>
                </div>
              </div>
              <div className="hidden 2xl:block lg:w-1/2">
                <img
                  className="max-w-full 2xl:max-w-[852px]"
                  src={`/images/log-app/hero/${language}.png`}
                  alt="LOG APP screenshots"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...handlers}
          className="flex flex-wrap"
        >
          <div className="lg:bg-navyBlueGray w-full lg:w-1/2 flex items-center justify-center pt-12 pb-8 lg:py-44">
            <img
              srcSet={`${slideImageSrcRetina} 2x`}
              src={slideImageSrc}
              alt="LoG App screenshot"
              className="md:max-w-[250px]"
            />
          </div>
          <div className="w-full lg:w-1/2 flex flex-wrap items-center justify-center lg:justify-start px-4 lg:px-0">
            <div className="max-w-[530px] lg:pl-12 2xl:pl-28">
              <h4 className="font-bold text-lg uppercase mb-3 lg:mb-12 text-center lg:text-left">
                {t('OUR APP ALLOWS YOU TO:')}
              </h4>
              <div className="-ml-2 md:-ml-6 text-center mb-4 lg:mb-12">
                <StyledTitle>
                  <HighlightedSubtitle className="text-center !mb-4 uppercase">
                    {currentSlide.title}
                  </HighlightedSubtitle>
                </StyledTitle>
              </div>
              <p className="text-lg font-light text-oceanGray text-center lg:text-left">
                {currentSlide.subtitle}
              </p>
              <div className="flex justify-center lg:justify-start">
                <div className="flex mt-6 lg:mt-12">
                  {getSlidesIndicators()}
                </div>
              </div>
              <div className="mt-4 lg:mt-12 pb-6 flex justify-center lg:justify-start">
                <button
                  type="button"
                  onClick={goToPreviousSlide}
                  className="mr-4"
                >
                  <img
                    src={arrowSvg}
                    className="transform rotate-180 w-6 h-6 lg:w-8 lg:h-8"
                    alt="previous slide"
                  />
                </button>
                <button type="button" onClick={goToNextSlide}>
                  <img
                    src={arrowSvg}
                    alt="next slide"
                    className=" w-6 h-6 lg:w-8 lg:h-8"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-brightYellow px-4 items-center justify-center py-4 hidden lg:flex">
          <p>
            <strong className="font-bold">
              {t('Download the app and learn more about the Laws')}
            </strong>
          </p>
          <div className="ml-16">
            <div className="flex w-full justify-center lg:justify-start">
              <a href={t('apple-app-store-link')} target="_blank" rel="noreferrer">
                <img
                  src={appleStoreButton}
                  alt=""
                  className="max-w-full h-[48px]"
                />
              </a>
              <a
                href={t('google-play-link')}
                target="_blank"
                className="ml-6"
                rel="noreferrer"
              >
                <img
                  src={googlePlayButton}
                  alt=""
                  className="max-w-full h-[48px]"
                />
              </a>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    featuredNewsItem: datoCmsFeaturedNewsItem(locale: { eq: $language }) {
      newsItem {
        title
        slug
        date
      }
    }
    logApp: datoCmsLogApp {
      seo {
        description
        title
      }
    }
  }
`

export default IndexPage
